import Head from 'next/head'

export default function seo({
  title,
  imageSrc,
  imageWidth,
  imageHeight,
  description,
  canonical,
  children,
  showBrandInTitle = true,
  schema = null
}) {
  const url = canonical ? `${process.env.NEXT_PUBLIC_BASEPATH}${canonical}` : ''

  const fullTitle = `${title}${showBrandInTitle ? ' - Atelier Milky' : ''}`

  return (
    <Head>
      <meta charSet="utf-8" />

      <title>{fullTitle}</title>
      <meta name="title" content={fullTitle} />
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />

      <meta property="twitter:card" content="summary" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={fullTitle} />
      <meta property="twitter:description" content={description} />

      {imageSrc && imageWidth && imageHeight ? (
        <>
          <meta property="og:image" content={imageSrc} />
          <meta property="og:image:width" content={imageWidth} />
          <meta property="og:image:height" content={imageHeight} />
          <meta property="twitter:image" content={imageSrc} />
          <meta property="twitter:image:width" content={imageWidth} />
          <meta property="twitter:image:height" content={imageHeight} />
        </>
      ) : (
        <>
          <meta property="og:image" content={`${process.env.NEXT_PUBLIC_BASEPATH}/brand/image-share.png`} />
          <meta property="og:image:alt" content="Logo Atelier Milky" />
          <meta property="og:image:width" content="250" />
          <meta property="og:image:height" content="250" />
          <meta property="twitter:image" content={`${process.env.NEXT_PUBLIC_BASEPATH}/brand/image-share.png`} />
          <meta property="twitter:image:alt" content="Logo Atelier Milky" />
          <meta property="twitter:image:width" content="250" />
          <meta property="twitter:image:height" content="250" />
        </>
      )}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: schema
            ? JSON.stringify(schema({ fullTitle, description, url }))
            : JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'WebPage',
                publisher: {
                  '@id': 'https://ateliermilky.com#identity'
                },
                copyrightHolder: {
                  '@id': 'https://ateliermilky.com#identity'
                },
                name: fullTitle,
                description: description,
                mainEntityOfPage: url
              })
        }}
      />

      {children}
    </Head>
  )
}
