const data = {
  id: 'cds3a',
  slug: 'creation-de-contenu',
  name: 'Création de contenu',
  description:
    "Atelier Milky vous propose la coordination et la production artistique d'images et vidéos au caractère fort. Alliant l'art de la composition à un sens certain de l'esthétisme, nous vous fournissons un contenu de qualité pour vos différentes plateformes.",
  page: {
    metaDescription:
      "Alliant l'art de la composition à un sens certain de l'esthétisme nous vous fournissons un contenu de qualité pour vos différentes plateformes.",
    bulletpoints: [
      {
        id: '01',
        name: 'Shooting classique',
        description: (
          <p>
            La force du contenu visuel en communication est à prendre en considération. En plus de l'information
            transmise, il permet de communiquer des émotions, souvent bien plus puissantes et efficaces que les mots.
            Voilà pourquoi Atelier Milky propose des shootings lifestyle disponibles en journée ou demi-journée.
          </p>
        ),
        concretlyTitle: 'Ce package comprend',
        concretly: [
          'Le photographe',
          'La retouche photo',
          "La remise d'environ 20 (demi-journée) ou 50 (journée) photos",
          "Ce package ne comprend pas la direction artistique pour le set-up, la location d'un lieu, le make-up artist et les mannequins"
        ]
      },
      {
        id: '02',
        name: 'Shooting avec direction artistique',
        description: (
          <p>
            Vous avez un produit mais vous ne savez pas comment le mettre en valeur ni comment vous démarquer. Nous
            imaginons et réalisons un concept d'image original et fort qui permettra de mettre votre produit en avant.
            Disponible en journée ou demi-journée.
          </p>
        ),
        concretlyTitle: 'Ce package comprend',
        concretly: [
          'Le photographe',
          'Le matériel nécessaire afin de créer le set up de shooting',
          'La retouche photo',
          "La remise d'environ 20 (demi-journée) ou 50 (journée) photos",
          "Ce package ne comprend pas la location d'un lieu, le make-up artist et les mannequins"
        ]
      },
      {
        id: '03',
        name: 'Réalisation de reels classiques',
        description: (
          <p>
            La vidéo est aujourd'hui devenue un format incontournable des réseaux sociaux. Elle prend de plus en plus de
            place aux côtés de la photo ou des autres formats et devient indispensable pour une stratégie de contenu
            efficace. Atelier Milky vous propose la réalisation de vidéos courtes que ce soit pour une utilisation sur
            Instagram ou TikTok.
          </p>
        ),
        concretlyTitle: 'Ce package comprend',
        concretly: [
          "La réalisation d'un Reel Instagram classique (sans montage complexe)",
          'La remise du Reel terminé',
          "La remise d'une stratégie comprenant la légende et les hashtags à utiliser",
          'Une photo de couverture'
        ]
      },
      {
        id: '04',
        name: 'Réalisation de reels professionnels',
        description: (
          <p>
            La vidéo est aujourd'hui devenue un format incontournable des réseaux sociaux. Elle prend de plus en plus de
            place aux côtés de la photo ou des autres formats et devient indispensable pour une stratégie de contenu
            efficace. Atelier Milky vous propose la réalisation de vidéos courtes avec une touche créative que ce soit
            pour une utilisation sur Instagram ou TikTok.
          </p>
        ),
        concretlyTitle: 'Ce package comprend',
        concretly: [
          "La réalisation d'un Reel Instagram élaboré (avec montage complexe et transitions surprenantes)",
          'La remise du Reel terminé',
          "La remise d'une stratégie comprenant la légende et les hashtags à utiliser",
          'Une photo de couverture'
        ]
      }
    ]
  }
}

export const home = {
  slug: data.slug,
  name: data.name,
  descriptionIndex:
    "Atelier Milky vous propose la coordination et la production artistique d'images et vidéos au caractère fort.",
  descriptionServicesPage: data.description,
  bullets: data.page.bulletpoints.map((thisBullet) => ({
    id: thisBullet.id,
    name: thisBullet.name
  })),
  image: {
    image: '/services/contenu-2.jpg',
    alt: "Table dressée pour deux, décorée d'une plante dans un pot et d'un panier de pain"
  }
}

export default data
