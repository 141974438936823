const data = {
  id: 'pze23',
  slug: 'creation-identite',
  name: "Création d'identité",
  description:
    "Pour se démarquer de la concurrence, chaque marque doit présenter une identité unique qui traduit ses valeurs et sa vision. Que ce soit une identité visuelle ou encore une identité de marque, Atelier Milky vous aide à créer le socle de la communication de votre entreprise. En s'inspirant de votre histoire, nous créons avec vous un storytelling fort et impactant qui marquera sans aucun doute votre audience.",
  page: {
    metaDescription:
      "L'image occupe une place centrale dans l'univers digital. Nous conceptualisons et façonnons avec vous l'identité de votre projet pour le rendre unique.",
    bulletpoints: [
      {
        id: '01',
        name: 'Logo & Identité visuelle',
        description: (
          <p>
            Nous créons avec vous une identité graphique qui vous ressemble et qui reflète la personnalité de votre
            marque. Composé d'un logo et d'autres éléments graphiques, ce package définit l'univers de votre marque.
          </p>
        ),
        concretlyTitle: 'Ce package comprend',
        concretly: [
          'Un rendez-vous en début de travail afin de récolter vos idées et discuter ensemble du projet.',
          "La création d'un logo et ses dérivés afin de l'exploiter sous tous les formats.",
          'Les éléments graphiques dérivés du logo.',
          "La création d'une palette de couleurs",
          "La remise d'un pack de polices",
          "Le tout sera remis sous forme de charte graphique comprenant les règles d'utilisation du logo et des éléments graphiques",
          '2 retours sont compris dans le package'
        ]
      },
      {
        id: '02',
        name: 'Identité de marque',
        description: (
          <p>
            Une identité de marque n'est pas simplement constituée d'un logo et d'une identité visuelle. Elle rassemble
            bien plus d'éléments, qui utilisés ensemble, traduisent la personnalité et les valeurs de la marque. Ce
            package, combiné au package Logo et Identité visuelle, vous apportera une approche complète et un
            storytelling harmonieux.
          </p>
        ),
        concretlyTitle: 'Ce package comprend',
        concretly: [
          'Un rendez-vous afin de comprendre les besoins de la marque',
          "L'analyse de votre marque afin d'en déterminer les valeurs, sa vision et surtout son positionnement communicationnel",
          "Le choix d'un vrai positionnement et d'un storytelling qui donnera une cohérence à votre marque",
          "La création d'une identité éditoriale ",
          "La création d'une charte photographique",
          'Une recommandation stratégique de vos réseaux sociaux',
          'Une séance de questions/réponses'
        ]
      },
      {
        id: '03',
        name: 'Supports de communication',
        description: (
          <p>
            Une fois votre identité créée, les possibilités de déclinaisons sur différents supports sont infinies.
            Atelier Milky vous propose plusieurs packages afin d'exploiter votre identité graphique au maximum.{' '}
          </p>
        ),
        concretlyTitle: 'Voici les packages disponibles',
        concretly: [
          'Création des supports de communication de base',
          "Création d'autres supports (flyers, affiches, etc.)",
          "Création d'identité pour les réseaux sociaux"
        ]
      },
      {
        id: '04',
        name: 'Site web',
        description: (
          <p>
            Centre névralgique de votre présence digitale, votre site web est l'endroit idéal pour partager toutes les
            informations concernant votre business. Cette vitrine digitale doit donc correspondre à vos valeurs et à
            votre image. Atelier Milky vous accompagne dans la création de A à Z d'un site internet optimisé à
            l'expérience utilisateur travaillée.
          </p>
        ),
        concretlyTitle: 'Ce package comprend',
        concretly: [
          'La réalisation du lay-out de votre nouveau site web',
          'La création de 5 pages de site maximum. Par exemple : Home, A propos, Services, Contact, Equipe',
          "L'intégration des éléments graphiques de votre identité visuelle",
          "L'optimisation SEO de ces pages (code)",
          "L'installation et l'implémentation du lay-out dans Shopify ou autre plateforme similaire",
          "L'intégration des différentes extensions (statistiques, mailchimp, intégration code Pinterest)",
          'Vous devez nous fournir les textes à incorporer sur le site web'
        ]
      }
    ]
  }
}

export const home = {
  slug: data.slug,
  name: data.name,
  descriptionIndex:
    "L'image occupe une place centrale dans l'univers digital. Nous conceptualisons et façonnons avec vous l'identité de votre projet pour le rendre unique.",
  descriptionServicesPage: data.description,
  bullets: data.page.bulletpoints.map((thisBullet) => ({
    id: thisBullet.id,
    name: thisBullet.name
  })),
  image: {
    image: '/services/indentite.jpg',
    alt: "Personne créant le croquis d'un logo"
  }
}

export default data
