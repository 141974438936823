import Link from 'next/link'

const data = {
  id: 'ef234',
  slug: 'strategie-digitale',
  name: 'Stratégie digitale',
  description:
    "Afin d'assurer une présence digitale optimale à nos clients, nous proposons différents types de stratégies. Nous pensons un plan d'actions efficace en fonction des objectifs définis et créons ensemble un projet qui vous ressemble.",
  page: {
    metaDescription:
      "Nous élaborons ensemble un plan d'action sur-mesure afin d'atteindre vos objectifs en fonction de votre secteur d'activité et de votre cible.",
    bulletpoints: [
      {
        id: '01',
        name: 'Audit',
        description: (
          <>
            <p>
              Le développement de votre compte Instagram et la mise en place d'une nouvelle stratégie passe par une
              étape clé : l'audit de ce dernier.
            </p>
            <p>
              Véritable diagnostic de votre présence, il vous permettra de repositionner votre stratégie, d'analyser ce
              qui fonctionne et de prendre de bonnes décisions afin d'atteindre vos objectifs. Atelier Milky propose
              actuellement 2 audits : l'audit Instagram et l'audit Facebook.
            </p>
            <p className="italic">
              Passage obligatoire pour le développement de toute stratégie digitale (exceptée la stratégie de
              lancement).
            </p>
          </>
        ),
        concretlyTitle: 'Ce package comprend',
        concretly: [
          'Une analyse de votre business, de vos objectifs et de vos KPIs',
          'Une analyse de votre compte : le branding, la bio, le type de contenu, les hashtags, le copywriting, les campagnes payantes.',
          'Une analyse et un guide de vos statistiques',
          'Un guide sur les formats proposés par la plateforme choisie fin de les utiliser de manière optimale',
          "Des conseils d'organisation et de gestion de temps",
          'Une analyse de vos concurrents/inspiration',
          'De nombreux trucs et astuces'
        ]
      },
      {
        id: '02',
        name: 'Stratégie digitale',
        description: (
          <>
            <p>
              Nous vous proposons une stratégie afin de booster vos réseaux sociaux. Après une analyse poussée de votre
              compte (audit), nous façonnons un calendrier comprenant environ 15 idées de publications à publier sur 1
              mois. Idée créative, conseils de réalisation, stratégie hashtags mais aussi légendes, nous vous guidons
              pas à pas dans le développement de votre présence digitale.
            </p>
            <p>Atelier Milky propose actuellement une stratégie digitale sur un réseau unique ou multi-réseaux.</p>
            <p className="italic">
              Pour les comptes déjà existants, qui ne décollent pas et qui ont besoin d'un coup de peps pour obtenir des
              résultats. Attention, la création de contenu imaginé dans la stratégie n'est pas compris dans le prix.
            </p>
          </>
        ),
        concretlyTitle: 'Ce package comprend',
        concretly: [
          "La mise en place d'objectifs précis",
          'Un calendrier éditorial',
          'Des idées de publications (stories, post, ou autre formats)',
          'Une stratégie comprenant une stratégie hashtags, des pistes pour vos légendes et des inspirations créatives',
          'De nombreux trucs et astuces',
          "Un guide d'utilisation du réseau",
          'Un accompagnement aux questions durant 1 mois après la remise de la stratégie'
        ]
      },
      {
        id: '03',
        name: 'Stratégie de lancement',
        description: (
          <>
            <p>
              Pour une entrée fracassante, nous vous proposons d'imaginer pour vous une stratégie de lancement. Que ce
              soit l'arrivée d'un nouveau produit, d'une marque ou d'une collection, Atelier Milky se charge de créer
              une stratégie en accord avec votre univers !
            </p>
            <p>
              Analyse de vos publics, de votre présence sur les réseaux sociaux, recherche de l'idée créative ou encore
              mise en place d'un calendrier éditorial, tout est pensé pour vous guider au mieux dans cette grande étape
              !
            </p>
            <p>Atelier Milky propose actuellement une stratégie digitale sur un réseau unique ou multi-réseaux.</p>
            <p className="italic">
              Pour les lancements de marque ou de produits. Une stratégie spécifique à la campagne sera mise en place.
            </p>
          </>
        ),
        concretlyTitle: 'Ce package comprend',
        concretly: [
          "L'analyse afin de déterminer votre présence digitale (dans le cas d'une stratégie multi-réseaux)",
          "L'analyse de la communication de vos concurrents",
          "Le point sur votre mission, vision et valeurs d'entreprise",
          "L'analyse de vos publics cibles afin de proposer le contenu le plus adéquat",
          "La mise en place d'objectifs précis pour une réelle stratégie de communication",
          'Une stratégie hashtags pour votre contenu',
          'Un calendrier éditorial qui reprend les différentes publications pour une vision globale du projet',
          "Des idées de publications (stories, post, ou autre formats) afin d'annoncer le lancement de votre produit sur 1 mois",
          'Une stratégie comprenant une stratégie hashtags, des pistes pour vos légendes et des idées créatives',
          "Une recommandation marketing d'influence et d'activation de la marque",
          'De nombreux trucs et astuces',
          'Le guide des réseaux sociaux développés',

          <>
            Nous pouvons également réaliser le contenu pour votre stratégie de lancement.{' '}
            <Link href="/services/creation-de-contenu">
              <a className="underline">En savoir plus</a>
            </Link>
            .
          </>
        ]
      },
      {
        id: '04',
        name: 'Stratégie influence',
        description: (
          <p>
            Le pouvoir de l'influence n'est plus à démontrer mais pour obtenir des résultats, une vraie stratégie est
            nécessaire. Atelier Milky vous accompagne dans l'élaboration d'un plan d'actions précis. Choix
            d'influenceurs, calendrier de campagne, idée créative, nous créons ensemble la stratégie influence qui vous
            convient en fonction de votre budget.
          </p>
        ),
        concretlyTitle: 'Ce package comprend',
        concretly: [
          'Une stratégie comprenant 5 influenceurs',
          'Une stratégie sur 1 mois',
          "Un guide pour cerner tous les aspects d'une campagne de marketing d'influence : prise de contact, choix des influenceurs, tenant et aboutissants d'une campagne, analyses de résultats.",
          "Une liste d'influenceurs qui correspondent à votre marque, valeurs et objectifs de campagne",
          'Un calendrier stratégique : prise de contact, idée créative, briefing, validation, publication',
          'Un exemple de contrat type que vous pourrez utiliser pour vos collaborations'
        ]
      }
    ]
  }
}

export const home = {
  slug: data.slug,
  name: data.name,
  descriptionIndex:
    "Afin d'assurer une présence digitale optimale à nos clients, nous proposons différents types de stratégies. Nous pensons un plan d'actions efficace en fonction des objectifs définis et créons ensemble un projet qui vous ressemble.",
  descriptionServicesPage: data.description,
  bullets: data.page.bulletpoints.map((thisBullet) => ({
    id: thisBullet.id,
    name: thisBullet.name
  })),
  image: {
    image: '/services/strategie-2.jpg',
    alt: "Un iPhone affichant le compte Instagram d'Atelier Milky déposé sur un ordinateur portable déposé sur une table noire."
  }
}

export default data
