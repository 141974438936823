const data = {
  id: 'ze21m',
  slug: 'formations',
  name: 'Formations',
  description:
    'Afin de toujours mieux vous guider dans la gestion de vos réseaux sociaux, Atelier Milky propose désormais des formations. Au travers de ces sessions, nous partagerons avec vous nos conseils pour une présence digitale optimale. Pour un accompagnement 360°, différents coachings sont disponibles ! De la création de contenu à la stratégie digitale, venez vous formez aux côtés du Milky Gang !',
  page: {
    metaDescription:
      "Atelier Milky propose également des formations afin de vous guider au mieux dans l'utilisation des canaux digitaux.",
    bulletpoints: [
      {
        id: '01',
        name: 'Ateliers pratiques mensuels',
        description: (
          <p>
            Au travers de 3 ateliers pratiques, nous vous proposons de vous accompagner dans la gestion de vos réseaux
            sociaux. Mise au point théorique, exercices et discussions, ces coachings ont été pensés pour répondre à vos
            besoins, tout dans une ambiance de partage d'expérience. Envie de vivre l'expérience ? Contactez-nous pour
            vous inscrire !
          </p>
        ),
        concretlyTitle: 'Les 3 ateliers disponibles sont',
        concretly: [
          'Comment réaliser des vidéos qui cartonnent pour les réseaux sociaux ?',
          "Comment augmenter la visibilité de son contenu sur Instagram avec une stratégie adaptée à l'évolution de la plateforme ? (Prochainement)",
          'Comment intégrer la gestion des réseaux sociaux à son temps de travail quand on vient de lancer un business ? (Prochainement)'
        ]
      },
      {
        id: '02',
        name: 'Formations entreprises',
        description: (
          <p>
            Vous avez un business ou vous souhaitez former votre équipe à la gestion des réseaux sociaux ? Atelier Milky
            sera ravi de développer pour vous un coaching personnalisé ! Après avoir déterminé l'objectif de cette
            formation, notre équipe se chargera de mettre au point une journée sur-mesure !
          </p>
        ),
        concretlyTitle: 'Ce package comprend',
        concretly: [
          "Une formation d'une journée (min. 4 personnes et max. 10 personnes)",
          'Un lunch',
          'Un support de travail',
          'Une mise au point stratégique',
          'Des exercices pratiques'
        ]
      }
    ]
  }
}

export const home = {
  slug: data.slug,
  name: data.name,
  descriptionIndex:
    "Atelier Milky propose également des formations afin de vous guider au mieux dans l'utilisation des canaux digitaux. Au travers des ces coachings, nous vous partageons conseils et bonnes pratiques sur divers thèmes.",
  descriptionServicesPage: data.description,
  bullets: data.page.bulletpoints.map((thisBullet) => ({
    id: thisBullet.id,
    name: thisBullet.name
  })),
  image: {
    image: '/services/formation.jpg',
    alt: "Une main qui tend un smartphone à la main d'une autre personne"
  }
}

export default data
