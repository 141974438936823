import creaId, { home as idHome } from './creation-identite'
import stratDigi, { home as stratDigiHome } from './strategie-digitale'
import creaContenu, { home as creaContenuHome } from './creation-de-contenu'
import formations, { home as formationsHome } from './formations'

export const servicesHomeData = [
  { id: idHome.slug, ...idHome },
  { id: stratDigiHome.slug, ...stratDigiHome },
  { id: creaContenuHome.slug, ...creaContenuHome },
  { id: formationsHome.slug, ...formationsHome }
]

export const allServices = {
  [idHome.slug]: creaId,
  [stratDigiHome.slug]: stratDigi,
  [creaContenuHome.slug]: creaContenu,
  [formationsHome.slug]: formations
}

export const pagemeta = {
  intro: (
    <>
      <p>
        D'expérience, nous avons constaté qu'il est essentiel d'aborder sa présence digitale de manière globale afin de
        garantir son succès. C'est pourquoi nous proposons une approche 360° du développement de votre entreprise en
        ligne. Nous proposons des packages alliant des domaines d'expertise trop souvent cloisonnés, tels que la
        stratégie digitale, la production de contenu ou encore le branding.
      </p>
      <p>
        Nos formules sont divisées sous forme de modules tout à fait adaptables à votre budget et à vos besoins. De
        notre côté, toute notre équipe travaille main dans la main pour vous proposer une approche harmonieuse et vous
        simplifier la tâche.
      </p>
    </>
  )
}
